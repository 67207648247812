﻿import React, { Suspense, lazy, useEffect, useState } from "react";
import axios from "axios";

import "bootstrap/dist/css/bootstrap.min.css";
import "../css/calculator.css";
import "../css/style.css";
import CryptoJS from "react-native-crypto-js";

// import loadable from "@loadable/component";

// import HeaderLanding from "./HeaderLanding";
// import FooterLanding from "./FooterLanding";
// import { Helmet } from "react-helmet";

const SignIn = React.lazy(() => import("./SignIn"));
const SignUp = React.lazy(() => import("./SignUp"));

const Header = React.lazy(() => import("./Header"));
const Main = React.lazy(() => import("./Main"));
const Footer_uat = React.lazy(() => import("./Footer_uat"));

var BASE_URL = window.location.origin + "/admin";
var FRONT_BASE_URL = window.location.origin;
const FILE_BASE_URL = window.location.origin;

// axios.get(`${BASE_URL}/get-token`)
//   .then((res) => {
//     var token = res.data.apiAuth.token; // Adjust based on API response
//     var key = res.data.apiAuth.key; // Adjust based on API response
//     var encryptedToken = CryptoJS.AES.encrypt(token, key).toString();
   
//     localStorage.setItem("tkn",encryptedToken)
//   })
//   .catch((error) => {
//     console.error("Error fetching token:", error);
//     alert(error);
//   });


const HEADER_AUTH = {
  Authorization: localStorage.getItem("tkn"),
};

console.log(HEADER_AUTH)

const OTHER_HEADER = {
  "Content-Type": "multipart/form-data",
  Authorization: localStorage.getItem("tkn"),
};

const App = (props) => {
  const [hidesidebar, setHidesidebar] = useState(false);

  const handlesidebar = (value) => {
    setHidesidebar(value);
  };
  //   (async function sendLocalStorageData() {
  //     // Define the API endpoint where you want to send the data
  //     // const apiEndpoint = "https://yourserver.com/api/save-data"; // Change this to your actual backend endpoint

  //     // Retrieve data from localStorage
  //     const storedData = localStorage.getItem("myData");

  //     if (storedData) {
  //       try {
  //         const parsedData = JSON.parse(storedData); // Parse stored JSON

  //         if (Array.isArray(parsedData) && parsedData.length > 0) {
  //           console.log("Sending localStorage data to server...");
  // debugger
  //           // const response = await fetch(apiEndpoint, {
  //           //   method: "POST",
  //           //   headers: {
  //           //     "Content-Type": "application/json"
  //           //   },
  //           //   body: JSON.stringify({ data: parsedData })
  //           // });
  //           // if (response.ok) {
  //           //   console.log("Data successfully sent to server!");
  //           //   localStorage.removeItem("formData"); // Clear localStorage after successful send
  //           // } else {
  //           //   console.error("Failed to send data:", await response.text());
  //           // }
  //         } else {
  //           console.log("No valid data found in localStorage.");
  //         }
  //       } catch (error) {
  //         console.error("Error processing localStorage data:", error);
  //       }
  //     } else {
  //       console.log("No form data found in localStorage.");
  //     }
  //   })();


  return window.location.href.includes("signin") ? (
    <div id="main">
      <Suspense fallback={<div>loading...</div>}>
        <SignIn />
      </Suspense>
    </div>
  ) : window.location.href.includes("signup") ? (
    <div id="main">
      <Suspense fallback={<div>loading...</div>}>
        <SignUp />
      </Suspense>
    </div>
  ) : (
    <div id="main">
      <Suspense fallback={null}>
        <Header hidesidebar={null} />
      </Suspense>
      <Suspense fallback={null}>
        <Main handlesidebar={handlesidebar} />
      </Suspense>
      <Suspense fallback={null}>
        <Footer_uat />
      </Suspense>
    </div>
  );

  // <div id="main">
  //   <Header hidesidebar={hidesidebar} />
  //   <Main handlesidebar={handlesidebar} />
  //   <Footer_uat />
  // </div>
};

export default App;

export { BASE_URL, FRONT_BASE_URL, FILE_BASE_URL, HEADER_AUTH, OTHER_HEADER };
